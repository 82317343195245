@import "../../common/styles/font-size";

.release-notes-page {
  max-width: 1172px;
  margin: auto;
  padding: 16px 0 70px;
  @media (max-width: 1199px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  & > h1 {
    margin: 55px 0px;
    text-align: center;
    @include text-48-fw-400;
  }
  .release-notes-content {
    .box-tabs {
      display: flex;
      flex-wrap: nowrap;
      @media (max-width: 575px) {
        flex-wrap: wrap;
      }
      .tabs-nav {
        display: flex;
        flex: none;
        flex-direction: column;
        position: relative;
        min-width: 50px;
        z-index: 2;
        @media (max-width: 575px) {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          overflow: auto hidden;
          width: 100%;
          min-width: 0;
          margin-bottom: 30px;
        }
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background: #f0f0f0;
          transition: all 0.4s ease;
          @media (max-width: 575px) {
            left: 0;
            bottom: 0;
            right: auto;
            top: auto;
            width: 100%;
            height: 2px;
          }
        }
        .btn-tab {
          position: relative;
          font-size: 16px;
          font-weight: 700;
          color: #667085;
          background: none;
          text-align: left;
          outline: 0;
          border: 0;
          border-right: 2px solid transparent;
          padding: 10px 30px 10px 0;
          margin: 0;
          transition: all 0.4s ease;
          @media (max-width: 575px) {
            white-space: nowrap;
            border-right: 0;
            border-bottom: 2px solid transparent;
            padding-right: 0;
            margin-right: 30px;
          }
          &.active {
            color: #ea2127;
            border-right-color: #ea2127;
            @media (max-width: 575px) {
              border-bottom-color: #ea2127;
            }
          }
        }
      }
      .tabs-content {
        flex: 1 1 auto;
        padding-left: 30px;
        @media (max-width: 575px) {
          width: 100%;
          padding: 0;
        }
      }
    }
    .box-change-logs {
      .item {
        font-size: 16px;
        margin-bottom: 30px;
        .title-item {
          font-weight: bold;
          font-size: 20px;
        }
        .box-content {
          padding-left: 20px;
        }
      }
    }
  }
}
