.nav__paging {
  width: auto;
  margin: 0;
  padding: 0;
  .nav-paging {
    // @include flex-center;
    display: flex;
    align-items: center;
    justify-content: center;
    // end @include flex-center;
    width: 100%;
    // border: 1px solid #747474;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 10px;
    gap: 6px;
    .nav__paging__list {
      // @include flex-center;
      display: flex;
      align-items: center;
      justify-content: center;
      // end @include flex-center;
      width: 100%;
      gap: 4px;
      padding: 0;
      margin: 0;
      li {
        // @include flex-center;
        display: flex;
        align-items: center;
        justify-content: center;
        // end @include flex-center;
        border-radius: 4px;
        flex-direction: row;
        :not(.arrow) {
          height: 32px;
          width: 32px;
          border: 1px solid #d0d5dd;
        }
        a:not(.arrow) {
          // @include flex-center;
          display: flex;
          align-items: center;
          justify-content: center;
          // end @include flex-center;
          height: 32px;
          width: 32px;
          border-radius: 4px;
        }
      }
      .tabs-page-active {
        height: 32px;
        width: 32px;
        border-radius: 4px;
        border: 1px solid #ea2127 !important;
        color: #ea2127;
        :not(.arrow) {
          border: none;
        }
      }
      .tabs-page-arrow {
        // @include flex-center;
        display: flex;
        align-items: center;
        justify-content: center;
        // end @include flex-center;
        width: 20px;
        height: 20px;
        border-radius: 6px;
        background-color: #e6e9ed;
        cursor: pointer;
        &:not(.disabled):hover {
          .arrow {
            border-color: #ea2127;
          }
        }
        .arrow {
          transition: all 0.4s ease;
        }
      }
    }
  }
  .paging {
    a {
      // @include text-14-400;
      text-align: center;
    }
  }
}
.nav__paging .arrow {
  border: solid #a3a3a3;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 2.5px;
}
.nav__paging .right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 4px;
}
.nav__paging .left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 4px;
}
.nav__paging .tabs-page {
  // @include flex-center;
  display: flex;
  align-items: center;
  justify-content: center;
  // end @include flex-center;
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border-radius: 4px;
  flex-direction: row;
}

/*Mobile styles*/
@media screen and (max-width: 414px) {
  .nav-droplist {
    display: none;
  }
}
