@import '../../common/styles/font-size';


.layout-announcement {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/images/background_announcement.png') center center;
  background-size: cover;
  color: #ffffff;
  @include text-16-fw-400;
  padding: 10px 0px;
  & > marquee {
    width: 46%;
  }
}

.layout-footer {

  background: #101828;
  color: #ffffff;
  padding: 31px 0px;
  margin: auto;
  .footer-text-14{
    @include text-14-fw-400;
  }
  .footer-text-22{
    @include text-22-fw-600;
  }

  .footer-started{
    margin: 0px auto 0px auto;
    position: relative;
    text-align: center;
    background: url('../../assets/images/bg_redwood_grain_footer.png') center center no-repeat;
    background-size: cover;
    border-radius: 20px;
    bottom: 120px;
    padding: 40px;
    & > h3 {
      @include  text-36-fw-700;
      color: white;
    }
    button {
      @include  text-16-fw-600;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #ffffff;
      padding: 14px 12px;
      color: #EA2127;
      &:hover {
        background: #FFE0E1;
        color: #EA2127;
        border: 1px solid #EA2127;
      }
    }
  }

  .footer-contact-us{
    margin: 0px auto 0px auto;
    position: relative;
    background: url('../../assets/images/bg_redwood_grain_footer.png') center center no-repeat;
    background-size: cover;
    border-radius: 20px;
    bottom: 120px;
    padding: 40px 114px 40px 114px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-contact-us-left {
      & > h3 {
        @include  text-36-fw-700;
        color: white;
      }
      & > p {
        @include  text-22-fw-600;
        color: white;
      }
    }

    .footer-contact-us-right {
      button {
        @include  text-16-fw-600;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #ffffff;
        padding: 14px 12px;
        color: #EA2127;
        &:hover {
          background: #FFE0E1;
          color: #EA2127;
          border: 1px solid #EA2127;
        }
      }
    }
   
    
  }

  .footer-subscribe{
    position: relative;
    background: url('../../assets/images/bg_redwood_grain_footer.png') center center no-repeat;
    background-size: cover;
    border-radius: 20px;
    bottom: 120px;
    padding: 40px;
    & > h3 {
      @include  text-36-fw-700;
      color: white;
      text-align: center;
    }
    .footer-wrap-email {
      max-width: 856px;
      background-color: #ffffff;
      color: #101828;
      margin: auto;
      display: flex;
      padding: 20px 40px;
      border-radius: 20px;
      & > label {
        @include text-12-fw-400;
        position: absolute;
      }
      & > input {
        flex: 1;
        margin: 20px 41px 0px 0px;
        @include text-16-fw-400;
        outline: none;
        border-width: 0 0 2px;
        border-color: #D0D5DD;
        &:focus {
          border-color: #EA2127;
        }
        &::placeholder {
          @include text-16-fw-400;
        }
      }
      & > button {
        @include  text-16-fw-600;
        background: #EA2127;
        border-radius: 4px;
        border: none;
        padding: 14px 24px;
        color: #ffffff;
        max-width: 162px;
        &:disabled {
          background-color: #999999;
        }
      }
    }
  }

  .footer-warp{
    display: flex;  
    gap: 24px;
    .footer-started-info{
      width: 301px;
      .footer-social {
        & > a {
          color: #ffffff;
        }
      }
      .footer-under-social {
        & > a {
          & > img:first-child {
            width: 47px;
          }
          & > img {
            width: 37px;
          }
        }
      }
    }
    .footer-link {
      // width: 175px; // Hide Pricing
      width: 259px;
      & > p {
        @include text-22-fw-600;
      }
      & > ul {
        list-style-type: none;
        padding-left: 0px;
        @include text-14-fw-400;
        & > a {
          text-decoration: none;
          color: #ffffff;
          &> :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .text-center {
    @include text-12-fw-400;
  }
}

/*Tablet styles*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .layout-footer {
      .w-1440 {
        .footer-contact-us {
          padding: 40px;
        }
        .footer-warp {
          .footer-started-info {
            & > h4 {
              @include text-18-fw-600;
            }
            & > p {
              @include text-14-fw-400;
            }
            .footer-social {
              & > h4 {
                @include text-18-fw-600;
              }
            }
          }
          .footer-link {
            & > p {
              @include text-18-fw-600;
            }
            li {
              @include text-12-fw-400;
            }
          }
        }
      }
  }
}

/** Mobile*/
@media screen and (min-width: 320px) and (max-width: 991px) {
  .layout-footer {
    padding: 31px 16px 31px 16px;
    .w-1440 {
      max-width: 991px;
      .footer-contact-us {
        padding: 40px;
        .footer-contact-us-left {
          & > h3 {
            @include  text-24-fw-700;
            color: white;
          }
          & > p {
            @include  text-18-fw-600;
            color: white;
          }
        }
      }
      .footer-started {
        background: url('../../assets/images/bg_mobile_redwood_grain_footer.png') center center no-repeat;
        background-size: cover;
        bottom: 131px;
        & > h3 {
          @include text-24-fw-700;
          color: white;
        }
        & > button {
          padding: 10px 12px;
          border: none;
        }
      }

      .footer-subscribe {
        padding: 20px;
        & > h3 {
          @include  text-26-fw-700;
          color: white;
          text-align: center;
        }
        .footer-wrap-email {
          flex-direction: column;
          gap: 20px;
          padding: 20px;
          & > input {
            margin: unset;
          }
          & > button {
            margin: auto;
          }
        }
      }
  
      .footer-warp {
        gap: 40px;
        flex-wrap: wrap;
        .footer-started-info {
          width: 100%;
        }
        .footer-link {
          // width: 151px; // Hide Pricing
          width: 213px;
        }
      }
  
      //de tam
      // .footer-logo {
      //   position: relative;
      //   bottom: 80px;
      // }
      // .footer-warp {
      //   position: relative;
      //   bottom: 85px;
      // }
    }
  }
}

/** Mobile*/
@media screen and (min-width: 320px) and (max-width: 768px) {
  .layout-footer {
    padding: 31px 16px 31px 16px;
    .w-1440 {
      max-width: 991px;
      .footer-contact-us {
        padding: 30px;
        flex-wrap: wrap;
        flex-direction: column;
        .footer-contact-us-left {
          & > h3 {
            @include  text-20-fw-700;
            color: white;
            margin-bottom: 5px !important;
          }
          & > p {
            @include  text-16-fw-600;
            color: white;
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
}